import React, { useEffect, useMemo, useRef, useState } from 'react'
import { animateStaticEntry, setStaticEntry } from './animation'
import SwipeCarouselTeam from './SwipeCarouselTeam'
import { useTranslation } from 'react-i18next'
import ListPerson from '../../ListPerson/ListPerson'

const CarouselTeam = (props) => {
  const {
    className,
    persons,
    limit,
    useSwipe,
    animation = {
      canStart: false,
      canPlay: false,
    },
    hasShuffleBtn,
  } = props
  const [shuffledPersons, setShuffledPersons] = useState(persons)
  const [isInitialized, setIsInitialized] = useState(false)
  const { t } = useTranslation()

  const $wrapper = useRef()
  const $hasRunEntryAnimation = useRef()

  const personsToDisplay = useMemo(() => {
    if (!limit && limit !== 0) {
      return shuffledPersons
    }
    return shuffledPersons.slice(0, limit)
  }, [shuffledPersons, limit])

  useEffect(() => {
    if (!animation.canStart || $hasRunEntryAnimation.current) {
      return
    }

    setStaticEntry($wrapper.current)
  }, [animation.canStart])

  useEffect(() => {
    if (
      !animation.canPlay ||
      !animation.canStart ||
      $hasRunEntryAnimation.current ||
      !$wrapper.current
    ) {
      return
    }

    const tl = animateStaticEntry({
      wrapper: $wrapper.current,
      onComplete: () => ($hasRunEntryAnimation.current = true),
    })

    return () => {
      tl && tl.kill()
    }
  }, [animation])

  useEffect(() => {
    setShuffledPersons([...persons].sort(() => 0.5 - Math.random()))
    setIsInitialized(true)
  }, [persons])

  const shuffleTeam = () => {
    setIsInitialized(false)
    setTimeout(() => {
      setShuffledPersons([...persons].sort(() => 0.5 - Math.random()))
      setIsInitialized(true)
    }, 300)
  }

  const classHide = isInitialized ? '' : 'c-alpha-0'
  return useSwipe ? (
    <SwipeCarouselTeam className={className} persons={personsToDisplay} />
  ) : (
    <>
      <ul
        className={`list-person list-person--static hide-scrollbar ${
          className && className.length > 0 ? className : ''
        } ${classHide}`}
        ref={$wrapper}
      >
        {personsToDisplay.map((item, idx) => {
          const person = item.data
          return <ListPerson key={idx} person={person} />
        })}
      </ul>
      {hasShuffleBtn && (
        <div className="f f-jc-center mt-8">
          <button className="btn btn--dark btn--small " onClick={shuffleTeam}>
            {t(`home:statements:about:shuffle`)}
          </button>
        </div>
      )}
    </>
  )
}

export default CarouselTeam
