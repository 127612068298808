import React, { memo, useRef } from 'react'

const HomeTitleLine = ({ title, isHoverable }) => {
  const $span = useRef([])

  return (
    <span className="home-title__container ft-truncat ft-no-select">
      <span className="home-title__line" role="presentation">
        {[...title].map((t, i) => {
          if (t === ' ') {
            return (
              <span
                key={i}
                ref={(ref) => ($span.current[i] = ref)}
                dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
              />
            )
          }
          return (
            <span ref={(ref) => ($span.current[i] = ref)} key={i}>
              {t}
            </span>
          )
        })}
      </span>
    </span>
  )
}

const HomeTitle = ({ titleParts, isHoverable }) => {
  const label = titleParts.join(' ')
  return (
    <h1 className="home-title ft-right" translate="no" aria-label={label}>
      {titleParts.map((title, idx) => (
        <HomeTitleLine isHoverable={isHoverable} key={idx} title={title} />
      ))}
    </h1>
  )
}

export default memo(HomeTitle)
