import { useEffect, useCallback, useState, useMemo } from 'react';
import { breakpoints, throttle } from '../helpers/utils';

const useBreakpoints = () => {
  const [bp, setBp] = useState('sm')
  
  const getBreakpoint = useCallback(() => {
    const vw = window.innerWidth
    
    if (vw >= breakpoints.md && vw < breakpoints.lg) {
      return 'md'
    } else if (vw >= breakpoints.lg && vw < breakpoints.xl) {
      return 'lg'
    } else if (vw >= breakpoints.xl) {
      return 'xl'
    }
    return 'sm'
  }, [])

  const onResize = useCallback(() => {
    setBp(getBreakpoint())
  }, [getBreakpoint])

  const throttledOnResize = useMemo(() => throttle(onResize, 200, true), [onResize])
  const isMobile = useMemo(() => bp === 'sm', [bp])
  const isTablet = useMemo(() => bp === 'md', [bp])

  useEffect(() => {
    window.addEventListener('resize', throttledOnResize)
    return () => window.removeEventListener('resize', throttledOnResize)
  }, [throttledOnResize])

  useEffect(() => {
    onResize()
  }, [onResize])

  return {
    bp,
    isMobile,
    isTablet
  }
  
};

export default useBreakpoints;
