import React, { useCallback } from 'react'
import { Mousewheel, FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PrismicBreakpointPicture } from '../../PrismicPicture'
import { animateCarouselEntry } from './animation'

const SwipeCarouselTeam = ({ className, persons }) => {
  const freeMode = {
    enabled: true,
    sticky: true,
  }
  const breakpoints = {
    1240: {
      spaceBetween: 32,
    },
    768: {
      spaceBetween: 24,
    },
  }
  const mousewheel = {
    forceToAxis: true,
  }
  const modules = [Mousewheel, FreeMode]

  const onSwiperUpdate = useCallback((s) => {
    const slides = [...s.slides]
    if (!slides.length) {
      return
    }
    animateCarouselEntry(slides)
  }, [])

  return (
    <div
      className={`list-person list-person--swiper ${className}`}
      id="list-person"
    >
      <Swiper
        freeMode={freeMode}
        mousewheel={mousewheel}
        modules={modules}
        grabCursor
        spaceBetween={24}
        breakpoints={breakpoints}
        slidesPerView="auto"
        onUpdate={onSwiperUpdate}
      >
        {persons.map((item, key) => {
          const person = item.data
          return (
            <SwiperSlide key={`${person.first_name.text}${key}`}>
              <div className="list-person__wrapper">
                <div className="list-person__img">
                  {person.picture && (
                    <PrismicBreakpointPicture
                      fallbackImg={person.picture}
                      fallbackAlt={`${person.full_name.text}`}
                      sources={{ all: person.picture_all }}
                      lazy
                    />
                  )}
                </div>
                <div className="list-person__body">
                  <p className="c-white">{person.first_name.text}</p>
                  <p className="list-person__role c-white c-alpha-6">
                    {person.role.text}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default SwipeCarouselTeam
