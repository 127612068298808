import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { routeNames, getRoutesByLang } from '../utils/helpers/routes'

import ClassicLayout from '../components/Layouts/ClassicLayout'
import Statement from '../components/Statement'
import Contact from '../components/Contact'
import CarouselTeam from '../components/Carousels/CarouselTeam'
import LinkAnchor from '../components/LinkAnchor/LinkAnchor'
import HomeTitle from '../components/HomeTitle/HomeTitle'

import { iconArrowBottom } from '../utils/icons'
import { graphql } from 'gatsby'

import { getPageData, getPrismicEdges } from '../utils/helpers/transformers'
import useAnimationState from '../utils/hooks/useAnimationState'

import {
  setEntry,
  setVisibleEntry,
  animateEntry,
} from '../utils/page-animations/home'

import ScrollTrigger from 'gsap/ScrollTrigger'
import { setShuffleVisible } from '../utils/page-animations/common'
import { breakpoints } from '../utils/helpers/utils'
import HomeServiceBlocks from '../components/HomeServiceBlocks'
import CardProject from '../components/Cards/CardProject'
import useBreakpoints from '../utils/hooks/useBreakpoints'
import AdvantagesList from '../components/AdvantagesList'
import QuoteSlider from '../components/QuoteSlider'
import ListTheyTrustUs from '../components/ListTheyTrustUs'

const Index = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext
  const { isMobile } = useBreakpoints()

  // INTN
  const localizedRoutes = getRoutesByLang(language)
  const { t } = useTranslation(['home', 'common'])

  // HELPERS
  const animationState = useAnimationState({ routeName })

  //STATE
  const [isTitleHoverable, setIsTitleHoverable] = useState(false)

  // REF
  const $hasRunEntryAnimation = useRef(false)
  const $hasRunAnimation = useRef(false)

  // DATA
  const pageData = useMemo(() => getPageData(data), [data])

  const persons = useMemo(() => getPrismicEdges(data, 'prismicPerson'), [data])
  const personsToDisplay = useMemo(
    () => persons.filter((p) => p.data.picture.url != null),
    [persons]
  )

  const selectRandomProject = (projects) => {
    const randomIndex = Math.round(Math.random() * (projects.length - 1))
    return projects[randomIndex]
  }

  const projects = useMemo(() => {
    let formatedProjects = []
    if (pageData && pageData.service_projects) {
      formatedProjects = pageData.service_projects.map((p) => {
        return {
          title: p.title.text,
          img: p.thumbnail,
          imgSources: {
            all: p.thumbnail,
            md: p.thumbnail,
          },
          category: p.category.text,
          link: p.link.uid,
        }
      })
    }

    const productProjects = formatedProjects.filter(
      (project) => project.category === 'Product Design'
    )
    const strategyProjects = formatedProjects.filter(
      (project) => project.category === 'Product Strategy'
    )
    const scaleProjects = formatedProjects.filter(
      (project) => project.category === 'Design at Scale'
    )
    const techProjects = formatedProjects.filter(
      (project) => project.category === 'Engineering'
    )

    return [
      selectRandomProject(strategyProjects),
      selectRandomProject(productProjects),
      selectRandomProject(techProjects),
      selectRandomProject(scaleProjects),
    ]
  }, [pageData])

  const projectsLeftColumn = projects.filter((_project, i) => i % 2 === 0)
  const projectsRightColumn = projects.filter((_project, i) => i % 2 !== 0)

  const titleParts = useMemo(
    () => [
      t('home:hero:title_0'),
      t('home:hero:title_1'),
      t('home:hero:title_2'),
      t('home:hero:title_3'),
    ],
    [t]
  )

  // HOOKS
  const onResize = useCallback(() => {
    const isMobile = window.innerWidth < breakpoints.md

    if (!isMobile && $hasRunEntryAnimation.current) {
      setShuffleVisible()
    }
  }, [])

  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setVisibleEntry()

      return
    } else {
      setEntry()
    }
  }, [animationState.canStart, animationState.isReady])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }
    $hasRunAnimation.current = true
    const tl = animateEntry({
      onCompleteTitleEntry: () => setIsTitleHoverable(true),
      onCompleteProjects: () => {
        ScrollTrigger.refresh()
      },
    })

    return () => {
      tl && tl.kill()
    }
  }, [animationState.canPlay])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.addEventListener('resize', onResize)
  }, [onResize])

  return (
    <ClassicLayout
      name={routeName}
      uri={props.uri}
      language={language}
      seo={{
        title: t('home:seo:title'),
        description: t('home:seo:description'),
      }}
      internationalRoute={pageContext.internationalRoute}
    >
      <section className="tpl-home__hero">
        <div className="container container-wide">
          <div className="hero--home__statement">
            <p className="mb-1 ft-default max-width-95p max-width-md-100p">
              {t('home:hero:desc')}
            </p>
            <div>
              <LinkAnchor
                to="#section-services"
                className="link link--primary"
                scrollBehavior="smooth"
              >
                <span>{t('home:hero:cta')}</span>
                <span className="link--arrow-down ml-2">{iconArrowBottom}</span>
              </LinkAnchor>
            </div>
          </div>
          <HomeTitle isHoverable={isTitleHoverable} titleParts={titleParts} />
        </div>
      </section>

      <section id="section-services">
        <div className="container">
          <HomeServiceBlocks language={language} animation={animationState} />
        </div>
      </section>
      <section className="tpl-home__clients">
        <ListTheyTrustUs
          language={language}
          link={true}
          clients={[
            'l-oreal',
            'asmodee',
            'bnpp',
            'ledger',
            'pathe',
            'aroma-zone',
            'frichti',
            'hennessy',
            'mondial-relay',
            'sncf-gares',
            'l-oreal',
          ]}
          animation={animationState}
          gridClass="col-lg-8 col-xl-7"
        />
      </section>
      <section className="tpl-home__projects" id="section-projects">
        <div className="container">
          <Statement
            animation={animationState}
            id="home-projects-statement"
            title={t('home:statements:project:title')}
            cta={{
              text: t('home:statements:project:cta'),
              to: localizedRoutes[routeNames.projects],
            }}
            desc={t('home:statements:project:desc')}
            className="statement--reverse"
            classNameTitle="col-md-4 col-lg-3 col-lg-offset-2 col-xl-offset-1"
            classNameDesc="col-md-12 col-lg-8 col-xl-6 col-lg-offset-1 col-xl-offset-2"
          />
        </div>
        <div className="section-project__list container">
          <div className="list list--home-project row">
            <div className="list__item mb-7 mb-md-0 col col-md-6 col-lg-7 col-xl-6 col-xl-offset-1">
              {projectsLeftColumn.map((project, i) => (
                <CardProject
                  title={project.title}
                  category={project.category}
                  img={project.img}
                  ctaLink={`${localizedRoutes[routeNames.service]}/${
                    project.link
                  }/?useCase=${project.title}`}
                  className={i === 1 ? 'card-project--sm' : ''}
                  key={project.title}
                />
              ))}
            </div>
            <div className="list__item mt-1 mt-md-0 col col-md-6 col-lg-7 col-xl-6">
              {projectsRightColumn.map((project, i) => (
                <CardProject
                  title={project.title}
                  category={project.category}
                  img={project.img}
                  imgSources={project.imgSources}
                  ctaLink={`${localizedRoutes[routeNames.service]}/${
                    project.link
                  }/?useCase=${project.title}`}
                  className={
                    (i === 0 && !isMobile) || (i === 1 && isMobile)
                      ? 'card-project--sm'
                      : ''
                  }
                  key={project.title}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-home__team" id="section-team">
        <div className="container">
          <Statement
            animation={animationState}
            id="home-team-statement"
            title={t('home:statements:about:title')}
            cta={{
              text: t('home:statements:about:cta'),
              to: localizedRoutes[routeNames.about],
            }}
            desc={t('home:statements:about:desc')}
            subdesc={t('home:statements:about:subdesc')}
            classNameTitle="col-md-4 col-lg-3 col-xl-2 col-lg-offset-1 col-xl-offset-3"
            className="statement-xs-padding statement--about"
            classNameDesc="col-md-8 col-lg-8 col-xl-7 col-lg-offset-1"
          />
          <div className="mt-5 no-container-padding-xs hide-desktop">
            <CarouselTeam
              persons={personsToDisplay}
              animation={animationState}
            />
          </div>
          <div className="tpl-home__team-carousel hide-mobile">
            <CarouselTeam
              persons={personsToDisplay}
              limit={4}
              animation={animationState}
              hasShuffleBtn={true}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <AdvantagesList animation={animationState} />
        </div>
      </section>
      <section className="tpl-home__quote">
        <div className="container">
          <QuoteSlider animation={animationState} />
        </div>
      </section>

      <section className="tpl-home__contact">
        <div className="container">
          <Contact animation={animationState} />
        </div>
      </section>
    </ClassicLayout>
  )
}

export default Index

export const queryHome = graphql`
  query ($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allPrismicHomePage(filter: { lang: { eq: $contentLang } }) {
      ...prismicHomePage
    }
    prismicPerson: allPrismicPerson(filter: { lang: { eq: $contentLang } }) {
      ...prismicPerson
    }
    prismicProjects: allPrismicProject(
      filter: {
        lang: { eq: $contentLang }
        data: {
          has_nda: { ne: true }
          has_page: { eq: true }
          thumbnail: { url: { ne: null } }
        }
      }
    ) {
      ...prismicProject
    }
  }
`
