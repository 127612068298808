import gsap from 'gsap'
import { easeInQuad, easeOutQuad } from '../../utils/constants/eases'
import {
  gsapParams,
  setAdvantagesListEntry,
  setClientsEntry,
  setContactEntry,
  setServicesEntry,
} from './common'
import { breakpoints, getPosition } from '../helpers/utils'

const selectors = {
  title: '.home-title',
  titleElements: '.home-title__container span',
  hero: {
    title: '.hero--home__statement h2',
    text: '.hero--home__statement p',
    cta: '.hero--home__statement div',
  },

  projects: {
    wrapper: '.section-project__list',
    items: '.card-project',
  },
  quote: {
    wrapper: '.tpl-home__quote',
    items: '.quote',
    points: '.quotes-slider__pagination',
  },
}

const setTitleEntry = (show) => {
  gsap.set(
    selectors.titleElements,
    show ? gsapParams.showInUp : gsapParams.fadeOutDown100
  )
}

const setHeroEntry = (show) => {
  gsap.set(
    [selectors.hero.title, selectors.hero.text, selectors.hero.cta],
    show ? gsapParams.show : gsapParams.fadeOut
  )
}

const setProjectsListEntry = (show) => {
  const isMobile = window.innerWidth < breakpoints.md

  const fadeOutParam = isMobile
    ? gsapParams.fadeOutDown30
    : gsapParams.fadeOutDown50

  const position = getPosition(
    document.querySelector(selectors.projects.wrapper)
  )
  if (window.scrollY >= position.to && !show) {
    gsap.set(selectors.projects.items, fadeOutParam)
  } else {
    gsap.set(
      selectors.projects.items,
      show ? gsapParams.showInUp : fadeOutParam
    )
  }
}

const setQuoteEntry = (show) => {
  const isMobile = window.innerWidth < breakpoints.md

  const fadeOutParam = isMobile
    ? gsapParams.fadeOutDown30
    : gsapParams.fadeOutDown50
  gsap.set(selectors.quote.items, show ? gsapParams.showInUp : fadeOutParam)
  gsap.set(selectors.quote.points, show ? gsapParams.showInUp : fadeOutParam)
}

const animateProjectsList = ({ isMobile, onComplete }) => {
  const position = getPosition(
    document.querySelector(selectors.projects.wrapper)
  )

  if (window.scrollY >= position.top) {
    gsap.to(
      selectors.projects.items,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        delay: 0.6,
        ease: easeOutQuad,
        stagger: {
          each: 0.025,
          grid: isMobile ? [1, 4] : [2, 2],
          from: 'start',
          axis: isMobile ? 'y' : 'x',
        },
      },
      0
    )
  } else {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: selectors.projects.wrapper,
        start: '10% 80%',
        end: 'bottom center',
        once: true,
      },
    })

    tl.to(
      selectors.projects.items,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
        stagger: {
          each: 0.025,
          grid: isMobile ? [1, 4] : [2, 2],
          from: 'start',
          axis: isMobile ? 'y' : 'x',
          onComplete,
        },
      },
      0
    )

    return tl
  }
}

const animateQuote = (isMobile) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.quote.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })

  tl.to(
    selectors.quote.items,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )

  tl.to(
    selectors.quote.points,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )

  return tl
}

const animateTitleEntry = ({ onComplete }) => {
  const tl = gsap.timeline({
    onComplete,
  })
  tl.to(selectors.titleElements, { ...gsapParams.fadeInUp, stagger: 0.03 }, 0.2)
  return tl
}

const animateHeroEntry = () => {
  const tl = gsap.timeline()

  const animationLength =
    document.querySelectorAll(selectors.titleElements).length * 0.03 +
    0.2 -
    0.4 +
    0.6

  tl.to(
    [selectors.hero.title, selectors.hero.text, selectors.hero.cta],
    { ...gsapParams.fadeIn, duration: animationLength, ease: easeInQuad },
    0.4
  )
  return tl
}

const animateEntry = (props) => {
  const onComplete = props && props.onComplete ? props.onComplete : () => {}
  const tl = gsap.timeline({ onComplete })
  tl.add(
    animateTitleEntry({
      onComplete: props.onCompleteTitleEntry,
    }),
    0
  )
  tl.add(animateHeroEntry(), 0)
  tl.add(animateProjectsList({ onComplete: props.onCompleteProjects }))

  return tl
}

const setEntry = () => {
  setTitleEntry()
  setHeroEntry()
  setQuoteEntry()
  setAdvantagesListEntry()
  setServicesEntry()
  setProjectsListEntry()
  setContactEntry()
  setClientsEntry()
}

const setVisibleEntry = () => {
  setTitleEntry(true)
  setHeroEntry(true)
  setQuoteEntry(true)
  setAdvantagesListEntry(true)
  setProjectsListEntry(true)
  setServicesEntry(true)
  setContactEntry(true)
  setClientsEntry(true)
}

export { animateEntry, setEntry, setVisibleEntry, animateQuote }
