import gsap from 'gsap'
import { ease1 } from '../../../utils/constants/eases'
import { gsapParams } from '../../../utils/page-animations/common'

const selectors = {
  listItems: '.list-person__item',
}
const setStaticEntry = (wrapper) => {
  if (!wrapper) {
    return
  }
  const elements = wrapper.querySelectorAll(selectors.listItems)
  elements && gsap.set(elements, { opacity: 0, translateY: '15%' })
}

const animateStaticEntry = (props) => {
  const onComplete = props && props.onComplete ? props.onComplete : () => {}

  if (!props.wrapper) {
    return
  }

  const tl = gsap
    .timeline({
      onComplete,
      scrollTrigger: {
        trigger: props.wrapper,
        start: '-50% center',
        end: 'bottom center',
        once: true,
      },
    })
    .pause()

  const elements = props.wrapper.querySelectorAll(selectors.listItems)
  elements &&
    tl.to(elements, { ...gsapParams.fadeInUp, stagger: 0.1, ease: ease1 }, 0.15)

  return tl
}

const animateCarouselEntry = (slides) => {
  if (!slides) {
    return
  }

  const wrappers = slides.map((s) => s.querySelector('.list-person__wrapper'))

  gsap.set(wrappers, { opacity: 0, translateY: 50 })
  gsap.to(wrappers, {
    ...gsapParams.fadeInUp,
    ease: ease1,
    stagger: 0.1,
    duration: 1,
    scrollTrigger: {
      trigger: '#list-person',
      start: '-50% center',
      end: 'bottom center',
      once: true,
    },
  })
}

export { setStaticEntry, animateStaticEntry, animateCarouselEntry }
