import React, { useEffect, useRef } from 'react'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { animateEntry, setEntry } from './animations'

const Statement = ({
  title,
  cta,
  desc,
  subdesc,
  className = '',
  id = '',
  classNameTitle = 'col-md-4 col-lg-3 col-lg-offset-1',
  classNameDesc = 'col-md-8 col-lg-9',
  animation = {
    isReady: false,
    canStart: false,
    canPlay: false,
  },
  animationParams = {
    start: '10% 90%',
    delay: 0,
  },
  moreContent,
}) => {
  const $wrapper = useRef()
  const $hasRunAnimation = useRef()

  // HOOKS
  useEffect(() => {
    setEntry({ statement: $wrapper.current })
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current || !$wrapper.current) {
      return
    }

    if (!animation.canStart && animation.isReady) {
      $hasRunAnimation.current = true
      setEntry({ statement: $wrapper.current }, true)
      return
    }
  }, [animation.canStart, animation.isReady])

  useEffect(() => {
    if (!animation.canPlay || $hasRunAnimation.current || !$wrapper.current) {
      return
    }

    $hasRunAnimation.current = true
    const tl = animateEntry({
      statement: $wrapper.current,
      params: animationParams,
    })

    return () => {
      $hasRunAnimation.current = false
      tl && tl.kill()
    }
  }, [animation, animationParams])

  return (
    <div
      className={`row statement ${className}`}
      id={id || null}
      ref={$wrapper}
    >
      <div className={`statement__title col mb-3 mb-md-0 ${classNameTitle}`}>
        {title && title.length > 0 && (
          <div className="ft-default ft-400">{title}</div>
        )}
        {cta && (
          <LocalizedTransitionLink
            to={cta.to}
            entryTransitionType={
              cta.entryTransitionType ? cta.entryTransitionType : ''
            }
            exitTransitionType={
              cta.exitTransitionType ? cta.exitTransitionType : ''
            }
            className="link link--primary link--arrow mt-1 mt-lg-2"
          >
            <span>{cta.text}</span>
          </LocalizedTransitionLink>
        )}
      </div>
      <div className={`col ${classNameDesc}`}>
        {desc && desc.length > 0 && (
          <h2
            className="statement__desc ft-400 h5"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        )}
        {subdesc && subdesc.length > 0 && (
          <p className="statement__subdesc ft-default-m-small mt-3 mt-md-2 mb-0">
            {subdesc}
          </p>
        )}
        {moreContent && moreContent}
      </div>
    </div>
  )
}

export default Statement
