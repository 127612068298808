import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AnimatedCard from '../AnimatedCard'
import { getRoutesByLang, routeNames } from '../../utils/helpers/routes'
import { animateServices } from '../../utils/page-animations/common'
import { breakpoints } from '../../utils/helpers/utils'

const HomeServiceBlocks = ({ language, animation }) => {
  const { t } = useTranslation(['home', 'common'])
  const localizedRoutes = getRoutesByLang(language)

  useEffect(() => {
    const isMobile = window.innerWidth < breakpoints.md

    if (animation.isReady && animation.canStart) {
      animateServices(isMobile)
    }
  }, [animation])

  return (
    <div className="row tpl-service__services">
      <div className="col mb-6 mb-md-8 col-lg-9 col-xl-3 col-lg-offset-1 col-xl-offset-2">
        <h5 className="ft-400 tpl-service__services__title">
          {t('home:statements:services:title')}
        </h5>
      </div>
      <div className="col col-lg-12 col-xl-8 col-lg-offset-1 col-xl-offset-1 ">
        <div className="mosaic mosaic-md-2 mosaic-lg-2 mosaic--services">
          <AnimatedCard
            item="item_1"
            url={localizedRoutes[routeNames.strategy]}
            svg="doodle"
            className="card-animated--strategy"
            duration={20}
            animation={animation}
          />

          <AnimatedCard
            item="item_2"
            url={localizedRoutes[routeNames.product]}
            svg="dashed-arrow"
            className="card-animated--product"
            duration={50}
            animation={animation}
          />
          <AnimatedCard
            item="item_3"
            url={localizedRoutes[routeNames.atScale]}
            svg="circle"
            className="card-animated--design"
            duration={20}
            animation={animation}
          />
          <AnimatedCard
            item="item_4"
            url={localizedRoutes[routeNames.tech]}
            svg="engineering"
            className="card-animated--engineering"
            duration={20}
            animation={animation}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(HomeServiceBlocks)
