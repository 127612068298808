import React, { memo, useCallback } from 'react'

// Note: you can't use css scroll-behavior: smooth because it causes side effects with other transitions
const LinkAnchor = ({ children, to, scrollBehavior, className }) => {
  const handleClickAnchor = useCallback(
    (event) => {
      event.preventDefault()
      const el = document.querySelector(to)
      if (el) {
        el.scrollIntoView({
          behavior: scrollBehavior,
          block: 'start',
        })
      }
    },
    [scrollBehavior, to]
  )
  return (
    <a className={className} href={to} onClick={handleClickAnchor}>
      {children}
    </a>
  )
}

export default memo(LinkAnchor)
