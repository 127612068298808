import React from 'react'
import { PrismicBreakpointPicture } from '../PrismicPicture'

const ListPerson = ({ person }) => {
  return (
    <li className="list-person__item">
      <div className="list-person__img mb-3">
        {person.picture && (
          <PrismicBreakpointPicture
            fallbackImg={person.picture}
            fallbackAlt={`${person.full_name.text}`}
            sources={{ all: person.picture_all }}
            lazy
          />
        )}
      </div>
      <div className="list-person__body">
        <p className="c-white">{person.first_name.text}</p>
        <p className="ft-secondary list-person__role c-white c-alpha-6 mb-0">
          {person.role.text}
        </p>
      </div>
    </li>
  )
}

export default ListPerson
