import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { animateQuote } from '../../utils/page-animations/home'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const QuoteSlider = ({ animation }) => {
  const sliderRef = useRef(null)
  const { t } = useTranslation(['home', 'common'])
  const quotes = [
    {
      id: 'Hardware Wallets',
      quote: t('home:statements:quotes:ledger'),
      client: 'Hardware Wallets',
      role: 'Lead Product',
      person: 'Rémi d’Alise',
      logo: '/images/ledger.svg',
    },
    {
      id: 'Jacques Marie Mage',
      quote: t('home:statements:quotes:jmm'),
      role: 'VP of eCommerce',
      person: 'Abigail Joseph',
      logo: '/images/jmm.svg',
    },
    {
      id: 'L’Oréal',
      quote: t('home:statements:quotes:loreal'),
      role: 'Design Director, BeautyTech',
      person: 'François Pain',
      logo: '/images/l-oreal.svg',
    },
    {
      id: 'Mediaspot',
      quote: t('home:statements:quotes:mediaspot'),
      role: 'Deputy General Director (VDM / Mediaspot)',
      person: 'Stéphane Koenig',
      logo: '/images/mediaspot.svg',
    },
  ]
  const [activeQuote, setActiveQuote] = useState(0)
  const [quoteWidth, setQuoteWidth] = useState(0)

  const getQuoteWidth = () => {
    setQuoteWidth(document.querySelector('.quote').clientWidth)
  }

  const onScroll = useCallback(() => {
    if (quoteWidth) {
      const offsetScroll = sliderRef.current.scrollLeft
      const currentQuote = Math.ceil(offsetScroll / quoteWidth)
      setActiveQuote(currentQuote)
    }
  }, [quoteWidth])

  useEffect(() => {
    window.addEventListener('resize', getQuoteWidth)

    return () => {
      window.removeEventListener('resize', getQuoteWidth)
    }
  }, [])

  useEffect(() => {
    const ref = sliderRef.current

    if (ref) {
      getQuoteWidth()
      sliderRef.current.addEventListener('scroll', onScroll)
    }

    return () => {
      ref.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, quoteWidth])

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateQuote()
    }
  }, [animation])

  const scrollToQuote = (index) => {
    gsap.to(sliderRef.current, {
      scrollTo: { y: 0, x: quoteWidth * index },
      duration: 0.6,
    })
  }

  return (
    <div className="row">
      <div className="col col-md-8 col--center">
        <div className="quotes-slider hide-scrollbar mb-4" ref={sliderRef}>
          {quotes.map((quote) => (
            <div className="quote" key={quote.id}>
              <p className="quote__main mb-4 mb-md-5">{quote.quote}</p>
              <div className="quote__body">
                <img
                  src={quote.logo}
                  className="mb-1 mb-md-0"
                  alt={`quote of ${quote.id}`}
                />
                <div>
                  <p className="ft-default mb-0">
                    {quote.role}
                    {quote.client && `, ${quote.client}`}
                  </p>
                  <p className="ft-secondary c-alpha-6 mb-0">{quote.person}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="quotes-slider__pagination">
          {quotes.map((quote, i) => (
            <button
              aria-label="slide to quote"
              onClick={() => scrollToQuote(i)}
              key={quote.id}
              className={`${activeQuote === i ? 'active' : ''}`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuoteSlider
