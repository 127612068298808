import React, { useEffect, useRef, useState } from 'react'
import LocalizedTransitionLink from '../../LocalizedTransitionLink'
import { useTranslation } from 'react-i18next'
import { iconArrowUp } from '../../../utils/icons'

const CardProject = ({ className = '', img, title, ctaLink, category }) => {
  const { t } = useTranslation(['common'])
  const [imgLoaded, setImgLoaded] = useState(false)
  const imageRef = useRef(null)

  useEffect(() => {
    if (imageRef.current && !imgLoaded && img) {
      imageRef.current.src = img.url
      setImgLoaded(true)
    }
  }, [imgLoaded, img])

  return (
    <LocalizedTransitionLink
      entryTransitionType="fadeIn"
      exitTransitionType="fadeOut"
      to={ctaLink}
      className={`card-project ${className}`}
      aria-label={t('home:statements:project:aria_label')}
      addTrailingSlash={false}
    >
      <div className="card-project__thumbnail">
        <img ref={imageRef} src={img.url} alt={img.alt} loading="lazy" />
      </div>
      <div className="mt-3 mt-md-5">
        <div className="card-project__title mb-2 mb-md-3">
          <h6 className="ft-400">{title}</h6>
          {iconArrowUp}
        </div>
        <p className="ft-secondary c-alpha-6 mb-0">{category}</p>
      </div>
    </LocalizedTransitionLink>
  )
}

export default CardProject
